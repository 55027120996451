import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import Head from 'next/head';

const MainContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  height: 100vh;
`;

const LoginContainer = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.sizing.scale200};
  padding: 40px 80px;
  margin: auto;
  background: ${({ theme }) => theme.colors.backgroundStark};
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
`;

const WelcomeText = styled.h1`
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizing.scale200};
  padding: 12px 0;
`;

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MainContainer>
      <Head>
        <title>Kargo - Sign In</title>
      </Head>
      <LoginContainer>
        <div className='self-center w-full'>
          <WelcomeText>Welcome to Kargo.</WelcomeText>
          <Button
            onClick={() => loginWithRedirect({ appState: { returnTo: '/' } })}
          >
            Sign In
          </Button>
        </div>
      </LoginContainer>
    </MainContainer>
  );
};

export default Login;
